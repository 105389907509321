<template>
  <div>

    <search-filter
        :items="incomingInvoices"
        :title="'Incoming invoices'"
        :keyTitleForAlphabeticalSort="'billNumber'"
        :additionalSortOptions="additionalSortOptions"
        :selectedFilters="['_filter_isActive']"
        :selectedView="'list'"
        :excludedKeys="['incomingInvoices']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        :allowAddByUpload="true"
        @columnClick="selectIncomingInvoice($event)"
        @addItem="addIncomingInvoice"
        @addItemByUpload="addIncomingInvoiceByUpload"
    >
      <!--      result infos-->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getTotalAmounts(items)) }} TTC</span>
      </template>

      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-incoming-invoice
            :incoming-invoice="item"
            @click="selectIncomingInvoice(item)"
            @deleteIncomingInvoice="deleteIncomingInvoiceAlert(item)"
        />
      </template>


      <!--      List view-->

      <!--      Cell bill number-->
      <template #listView_cell_billNumber="{item}">
        <span v-if="item.billNumber != null">{{ item.billNumber }}</span>
        <span v-else>-</span>
      </template>

      <!--      Cell billing date-->
      <template #listView_cell_billingDate="{item}">
        {{ item.billingDate|moment('L') }}
      </template>

      <!--      Column type-->
      <template #listView_cell_type="{item}">
        <icon
            v-b-tooltip.hover.left="capitalize($t(item.type))"
            :icon="getIncomingInvoiceIconType(item).icon"
            :library="getIncomingInvoiceIconType(item).library?getIncomingInvoiceIconType(item).library:'fas'"
        />
      </template>

      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`invoice-row-${item.id}`"
            size="32"
            :variant="`light-${getIncomingInvoiceIconStatus(item).variant}`"
        >
          <icon
              :icon="getIncomingInvoiceIconStatus(item).icon"
              :library="getIncomingInvoiceIconStatus(item).library?getIncomingInvoiceIconStatus(item).library:'fas'"
          />
        </b-avatar>
        <b-tooltip
            :target="`invoice-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getIncomingInvoiceStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Column payment status-->
      <template #listView_head_paymentStatus="{item}">
        <icon icon="cash-register"/>
      </template>
      <template #listView_cell_paymentStatus="{item}">
        <b-badge
            v-if="item._paymentStatus != null"
            pill
            :variant="displayPaymentStatus(item._paymentStatus).variant"
        >
          {{ displayPaymentStatus(item._paymentStatus).value }}
        </b-badge>
      </template>

      <!--      Cell contact-->
      <template #listView_cell_supplierCompany="{item}">
        <span v-if="item.supplierCompany != null">{{ item.supplierCompany._display }}</span>
        <span v-else>-</span>
      </template>

      <!--      Column accountant downloaded-->
      <template #listView_head_accountantDownloaded="{item}">
        <icon v-b-tooltip.hover.top="capitalize($t('DownloadedForAccountant'))" icon="copy"/>
      </template>
      <template #listView_cell_accountantDownloaded="{item}">
        <span v-if="item.lastExport != null">
          {{ item.lastExport|moment('L') }}
        </span>
        <span v-else>-</span>
      </template>


      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit -->
          <button-edit
              @click.native.stop="selectIncomingInvoice(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
          <!--          <icon-->
          <!--              @click.native.stop="selectIncomingInvoice(item)"-->
          <!--              v-b-tooltip.hover.left="$t('Edit')"-->
          <!--              icon="edit"-->
          <!--              class="cursor-pointer"/>-->

          <!--          Download-->
          <button-download
              @click.native.stop="downloadIncomingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
          <!--          &lt;!&ndash;          Download&ndash;&gt;-->
          <!--          <icon-->
          <!--              @click.native.stop="downloadIncomingInvoice(item)"-->
          <!--              v-b-tooltip.hover.left="$t('Download')"-->
          <!--              icon="download"-->
          <!--              :disabled="item.receipt == null"-->
          <!--              class="cursor-pointer mx-1"/>-->

          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Add payment-->
            <b-dropdown-item
                @click.stop="addPayment('incomingInvoice', item)"
            >
              <icon
                  icon="cash-register"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('addPayment')) }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateIncomingInvoiceLocal(item, true)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Un archivate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="archivateIncomingInvoiceLocal(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateIncomingInvoice(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                @click.stop="deleteIncomingInvoiceAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </template>

    </search-filter>


    <!-- modal outgoing invoice by upload-->
    <modal-upload-incoming-invoice
        :singleFile.sync="newOutgoingInvoiceFile"
        :documentType="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :isOpen.sync="outgoingInvoiceByUploadModalShow"
    />


    <!-- modal incoming invoice-->
    <modal-incoming-invoice
        :incomingInvoice.sync="currentIncomingInvoice"
        :title="incomingInvoiceModalTitle"
        :isOpen.sync="incomingInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedIncomingInvoiceLocal"
    />

    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        @submitValidatedPayment="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinIncomingInvoices } from '@/views/purchases/incomingInvoices/mixinIncomingInvoices'
import { useIncomingInvoices } from '@/views/purchases/incomingInvoices/useIncomingInvoices'
import { mixinPayments } from '../../../mixins/mixinPayments'
import { displayPaymentStatus } from '../../../utils/utils'

import SearchFilter from '@/components/searchFilter/SearchFilter'
import CardIncomingInvoice from '@/components/card/IncomingInvoice'
import i18n from '@/libs/i18n'
import ButtonDownload from '@/components/button/Download'
import ButtonEdit from '@/components/button/Edit'
import ButtonDropdown from '@/components/button/Dropdown'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    SearchFilter,
    CardIncomingInvoice,
    ButtonDownload,
    ButtonEdit,
    ButtonDropdown,
  },
  mixins: [mixinIncomingInvoices, mixinPayments],
  props: {
    incomingInvoices: {
      type: Array,
      default: () => []
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'billNumber', label: '#', sortable: true },
      { key: 'billingDate', label: i18n.t('BillingDate'), sortable: true },
      { key: 'type', label: i18n.t('type'), sortable: false },
      { key: 'status', label: i18n.t('status'), sortable: false },
      { key: 'paymentStatus', label: i18n.t('paymentStatus'), sortable: false },
      { key: 'supplierCompany', label: i18n.t('supplier'), sortable: true },
      // { key: 'title', label: i18n.t('title'), sortable: false },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
      { key: 'accountantDownloaded', label: i18n.t('accountantDownloaded'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const {
      getIncomingInvoiceStatus,
      getIncomingInvoiceIconStatus,
      getIncomingInvoiceIconType,
    } = useIncomingInvoices()

    const enableFilters = () => {
      if (props.incomingInvoices.length) {
        props.incomingInvoices[0]._filters.forEach(filter => {
          if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      displayPaymentStatus,

      // Data
      additionalSortOptions,
      tableColumns,

      // Computed

      // Methods
      getIncomingInvoiceStatus,
      getIncomingInvoiceIconStatus,
      getIncomingInvoiceIconType,
      enableFilters,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    incomingInvoices: function () {
      this.enableFilters()
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>