<template>
  <div>
    <b-row>

      <b-col
          md="4"
          v-for="(payment, index) in incomingInvoice.bankOperations"
          :key="index"
      >
        <payment-content
            :payment="payment"
            @edit="selectPayment('incomingInvoice', incomingInvoice, payment)"
            @delete="deletePayment(index)"
        />
      </b-col>

      <b-col md="4">
        <add-content
            @click="addPayment('incomingInvoice', incomingInvoice)"
        />
      </b-col>

    </b-row>


    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        @submitValidatedPayment="submitPayment"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinPayments } from '../../../../mixins/mixinPayments'

import PaymentContent from '../../../card/components/PaymentContent'
import AddContent from '../../../card/components/New'
import store from '@/store'

export default {
  components: {
    PaymentContent,
    AddContent
  },
  mixins: [mixinPayments],
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    submitPayment () {
      if ('id' in this.currentPayment) {
        let index = this.incomingInvoice.bankOperations.findIndex(bo => bo.id == this.currentPayment.id)
        this.incomingInvoice.bankOperations.splice(index, 1, JSON.parse(JSON.stringify(this.currentPayment)))
      } else {
        this.currentPayment._balance = this.currentPayment.credit - this.currentPayment.debit
        this.currentPayment.paymentMethod = store.getters['paymentMethod/getPaymentMethod'](this.currentPayment.paymentMethod)

        this.incomingInvoice.bankOperations.push(this.currentPayment)
      }

      this.paymentModalShow = false
    },
    deletePayment (paymentIndex) {
      this.incomingInvoice.bankOperations.splice(paymentIndex, 1)
      console.log(this.incomingInvoice.bankOperations)
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>