<template>
  <generic-content
      :isLinkable="false"
      :isEditable="false"
      :isDownloadable="false"
      :isDeletable="false"
      @click="$emit('click')"
  >
    <template #iconContent>
      <b-avatar
          size="32"
          variant="light-primary"
          class="my-auto"
      >
        <icon
            icon="plus-circle"
        />
      </b-avatar>
    </template>

    <template #bodyContent>
      <h6 class="transaction-title text-truncate">
        {{ $t('Add')}}
      </h6>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {capitalize} from '../../../utils/filter'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>